import { Component } from '@angular/core';

@Component({
	selector: 'pcs-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'pcs-front-angular';

	onActivate($event: any) {
		window.scroll(0, 0);
	}
}
