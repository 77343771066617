import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { tours } from './products-list/products-list-data';
import { Tour } from './models/tour';
import { TourDescription } from './models/tour-description';
import { TourDetails } from './models/tour-details';

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	tours: (Tour | TourDescription)[];
	toursDetails: TourDetails[];

	constructor(private http: HttpClient) {
	}

	public getToursList(): Observable<(Tour | TourDescription)[]> {
		if (!!this.tours) {
			return of(this.tours);
		}
		return this.http
			.get<(Tour | TourDescription)[]>('assets/data/products-list.json')
			.pipe(
				tap(products => this.tours = products)
			);
	}

	public getToursDetailsList(): Observable<TourDetails[]> {
		if (!!this.toursDetails) {
			return of(this.toursDetails);
		}
		return this.http
			.get<TourDetails[]>('/assets/data/products-details-list.json')
			.pipe(
				tap(productsDetails => this.toursDetails = productsDetails)
			);
	}
}
