import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/language';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	public languages: Language[];

	constructor(private http: HttpClient, private translate: TranslateService) {
	}

	public getLanguages(): Observable<Language[]> {
		if (!!this.languages) {
			return of(this.languages);
		}
		return this.http
			.get<Language[]>('assets/data/languages.json')
			.pipe(
				tap(languages => this.languages = languages)
			);
	}

	public changeLanguage(code: string) {
		if (code !== this.translate.currentLang) {
			this.translate.use(code);
			localStorage.setItem('pcs-default-language', code);
		}
	}

	public initialize(): void {
		this.translate.addLangs(['en', 'fr']);

		const defaultCachedLanguage = localStorage.getItem('pcs-default-language');

		if (!!defaultCachedLanguage) {
			this.translate.use(defaultCachedLanguage);
		} else {
			const browserLang = this.translate.getBrowserLang();
			this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
		}
	}
}
