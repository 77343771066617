import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LanguageService } from '@core/services/language.service';

@Injectable({
	providedIn: 'root'
})
export class LanguageResolverService implements Resolve<string> {

	constructor(
		private languageService: LanguageService,
		private router: Router
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | Observable<string> | Promise<string> | string {
		const lang = route.paramMap.get('lang');
		if (lang === 'en' || lang === 'fr') {
			this.languageService.changeLanguage(lang);
			return lang;
		}

		this.router.navigate(['/']);

		return undefined;
	}
}
