import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { forkJoin } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import.guard';
import { ProductService } from '../features/product/product.service';
import { FaqService } from '../features/faq/faq.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { translateStatement } from '@angular/compiler-cli/src/ngtsc/translator';
import { LanguageService } from './services/language.service';


export function startupFactory(productService: ProductService, faqService: FaqService, languageService: LanguageService) {
	return () => forkJoin(
		productService.getToursDetailsList(),
		productService.getToursList(),
		faqService.getFaqTopicsList(),
		languageService.getLanguages())
		.toPromise();
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		ScrollToModule.forRoot(),
		TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient]
				}
			}
		)
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: startupFactory,
			deps: [ProductService, FaqService, LanguageService],
			multi: true
		},
	]

})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule, languageService: LanguageService) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
		languageService.initialize();
	}
}
