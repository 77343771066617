import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LanguageResolverService } from './language-resolver.service';


const routes: Routes = [
	{
		path: ':lang/home',
		loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
		resolve: {
			lang: LanguageResolverService
		}
	},
	{
		path: ':lang/tour',
		loadChildren: () => import('./features/tour/tour.module').then(m => m.TourModule),
		resolve: {
			lang: LanguageResolverService
		}
	},
	{
		path: ':lang/about-us',
		loadChildren: () => import('./features/about-us/about-us.module').then(m => m.AboutUsModule),
		resolve: {
			lang: LanguageResolverService
		}
	},
	{
		path: ':lang/faq',
		loadChildren: () => import('./features/faq/faq.module').then(m => m.FaqModule),
		resolve: {
			lang: LanguageResolverService
		}
	},
	{
		path: ':lang/legal-terms',
		loadChildren: () => import('./features/legal-terms/legal-terms.module').then(m => m.LegalTermsModule),
		resolve: {
			lang: LanguageResolverService
		}
	},
	{
		path: '',
		redirectTo: 'en/home',
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: 'en/home',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		anchorScrolling: 'enabled',
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
