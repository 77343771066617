import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FaqTopic } from './models/faq-topic';

@Injectable({
	providedIn: 'root'
})
export class FaqService {

	faqTopics: FaqTopic[];
	currentFaqTopic: FaqTopic;

	constructor(private http: HttpClient) {
	}

	public getFaqTopicsList(): Observable<FaqTopic[]> {
		if (!!this.faqTopics) {
			return of(this.faqTopics);
		}
		return this.http
			.get<FaqTopic[]>('assets/data/faq.json')
			.pipe(
				tap(faqTopics => this.faqTopics = faqTopics)
			);
	}

	public getFaqtopicById(id: number): FaqTopic {
		const faqTopic = this.faqTopics.find(topic => topic.id === id);

		if (!!faqTopic) {
			return faqTopic;
		}

		return null;
	}

	public changeCurrentFaqTopic(faqTopic: FaqTopic) {
		this.currentFaqTopic = faqTopic;
	}
}
